import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getGradesWithClasses = () => {
  return axios.get(
    `${base.apiUrl}reports/get-grades-classes/${localStorage.getItem(
      "instituteId"
    )}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getTeachers = () => {
  return axios.get(
    `${base.apiUrl}reports/get-teachers?instituteId=${localStorage.getItem(
      "instituteId"
    )}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getStudents = (gradeId, classRoomId) => {
  return axios.get(
    `${base.apiUrl}reports/get-students?instituteId=1${
      gradeId !== "0" ? `&gradeId=${gradeId}` : ""
    }${classRoomId !== "0" ? `&classRoomId=${classRoomId}` : ""}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getChampions = (gradeId, classRoomId) => {
  return axios.get(
    `${base.apiUrl}reports/get-champions?instituteId=${localStorage.getItem(
      "instituteId"
    )} ${gradeId !== "0" ? `&gradeId=${gradeId}` : ""} ${
      classRoomId !== "0" ? `&classRoomId=${classRoomId}` : ""
    }`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getHomeworksAndPoints = (
  page,
  limit,
  date,
  month,
  fromDate,
  toDate,
  teacher,
  grade
) => {
  return axios.get(
    `${
      base.apiUrl
    }reports/get-homeworks-and-points/${page}/${limit}?instituteId=${localStorage.getItem(
      "instituteId"
    )}${date != null ? `&date=${date}` : ""}${
      month != null ? `&month=${month}` : ""
    }
    ${
      fromDate != null ? `fromDate=${fromDate}&toDate=${toDate}` : ""
    }&teacherId=${teacher}&instituteGradeId=${grade}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getClassroomHomeworks = (
  page,
  limit,
  classRoom,
  teacher,
  date,
  month,
  fromDate,
  toDate
) => {
  return axios.get(
    `${
      base.apiUrl
    }/reports/get-classroom-homeworks/${page}/${limit}?classRoomId=${classRoom}&teacherId=${teacher}&${
      date != null ? `&date=${date}` : ""
    }${month != null ? `&month=${month}` : ""}
    ${fromDate != null ? `fromDate=${fromDate}&toDate=${toDate}` : ""}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getAttendance = (page, limit, grade, date, month, fromDate, toDate) => {
  return axios.get(
    `${
      base.apiUrl
    }/reports/get-attendance/${page}/${limit}?instituteId=${localStorage.getItem(
      "instituteId"
    )}&instituteGradeId=${grade}&${date != null ? `&date=${date}` : ""}${
      month != null ? `&month=${month}` : ""
    }
    ${fromDate != null ? `fromDate=${fromDate}&toDate=${toDate}` : ""}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getClassroomAttendance = (
  page,
  limit,
  classRoom,
  date,
  month,
  fromDate,
  toDate
) => {
  return axios.get(
    `${
      base.apiUrl
    }/reports/get-classroom-attendance/${page}/${limit}?classRoomId=${classRoom}&${
      date != null ? `&date=${date}` : ""
    }${month != null ? `&month=${month}` : ""}
    ${fromDate != null ? `fromDate=${fromDate}&toDate=${toDate}` : ""}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const reportServices = {
  getGradesWithClasses,
  getTeachers,
  getStudents,
  getChampions,
  getHomeworksAndPoints,
  getClassroomHomeworks,
  getAttendance,
  getClassroomAttendance,
};

export default reportServices;
