import React, { useEffect, useState } from "react";
import ReportLayout from "../../../components/root/ReportLayout";
import { useTranslation } from "react-i18next";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import LoadingData from "../../../components/common/LoadingData";
import reportServices from "../../../services/report.services";
import FailedAlert from "../../../components/common/FailedAlert";
import NoRecords from "../../../components/reports/NoRecords";
import { format } from "date-fns";

export default function GradesWithClassrooms() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [showError, setShowError] = useState(false);

  const pages = [
    {
      name: t("Reports"),
      href: "/reports/index",
      current: false,
    },
    { name: t("GradesClassrooms"), href: "#", current: true },
  ];

  useEffect(() => {
    reportServices
      .getGradesWithClasses()
      .then((response) => {
        setData(response.data.report);
        setIsLoading(false);
      })
      .catch((error) => {
        setShowError(true);
        setIsLoading(false);
      });
  }, []);

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <>
      <FailedAlert
        show={showError}
        message={t("SomethingWentWroing")}
        close={handleCloseError}
      />

      <ReportLayout title={t("GradesClassrooms")} pages={pages}>
        {isLoading && <LoadingData />}
        {!isLoading &&
          data.map((item) => (
            <div
              key={item.id}
              className="mt-5 bg-white px-5 pt-5 rounded-lg shadow-lg "
            >
              <div className="px-4 sm:px-0">
                <h3 className="text-base font-bold leading-10 text-gray-900">
                  {item.arabicName}
                </h3>
              </div>
              <div className="mt-6 ">
                <ul className="divide-y divide-gray-100">
                  {item.classrooms.length === 0 && <NoRecords />}
                  {item.classrooms.map((classroom) => (
                    <li
                      key={classroom.id}
                      className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
                    >
                      <div>
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {classroom.arabicName}
                        </p>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                          <svg
                            viewBox="0 0 2 2"
                            className="h-0.5 w-0.5 fill-current"
                          >
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          <p>
                            <time dateTime={classroom.createdAt}>
                              {format(
                                new Date(classroom.createdAt),
                                "MMMM d, y"
                              )}
                            </time>
                          </p>
                        </div>
                      </div>
                      <dl className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
                        <div className="flex w-16 gap-x-2.5">
                          <dt>
                            <UserGroupIcon
                              className="h-6 w-6 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="text-sm leading-6 text-gray-900">
                            {classroom.studentsCount}
                          </dd>
                        </div>
                      </dl>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
      </ReportLayout>
    </>
  );
}
