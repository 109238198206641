import React, { useEffect, useState } from "react";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AcademicCapIcon,
  UserGroupIcon,
  UserIcon,
  StarIcon,
  ChartPieIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import SelectGradeClassRoomModal from "../../components/reports/SelectGradeClassRoomModal";

export default function Reports() {
  const [selectGradeClassRoomForStudents, setSelectGradeClassRoomForStudents] =
    useState(false);
  const [
    selectGradeClassRoomForChampions,
    setSelectGradeClassRoomForChampions,
  ] = useState(false);
  const [selectedGradeId, setSelectedGradeId] = useState(0);
  const [selectedClassRoomId, setSelectedClassRoomId] = useState(0);

  const actions = [
    {
      title: "GradesClassrooms",
      href: "/reports/grades-with-classes",
      icon: AcademicCapIcon,
      iconForeground: "text-indigo-700",
      iconBackground: "bg-indigo-50",
      type: "grades_class_rooms",
    },
    {
      title: "Teachers",
      href: "/reports/teachers",
      icon: UserIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
      type: "teachers",
    },
    {
      title: "Students",
      href: `/reports/students/${selectedGradeId}/${selectedClassRoomId}`,
      icon: UserGroupIcon,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      type: "students",
    },

    {
      title: "Champions",
      href: `/reports/champions/${selectedGradeId}/${selectedClassRoomId}`,
      icon: StarIcon,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
      type: "champions",
    },
    {
      title: "points",
      href: "/reports/points",
      icon: FlagIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      type: "points",
    },
    {
      title: "Attendance",
      href: "/reports/attendance",
      icon: ChartPieIcon,
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
      type: "attendance",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const { t } = useTranslation();
  const navigate = useNavigate();

  const setSelectedGrade = async (gradeId) => {
    setSelectedGradeId(gradeId);
  };

  const setSelectedClassRoom = async (classRoomId) => {
    setSelectedClassRoomId(classRoomId);
  };

  function showReport(action) {
    if (
      action.type === "grades_class_rooms" ||
      action.type === "teachers" ||
      action.type === "points" ||
      action.type === "attendance"
    ) {
      //window.open(action.href, "_blank", "rel=noopener noreferrer");
      navigate(action.href);
    } else if (action.type === "students") {
      setSelectGradeClassRoomForStudents(true);
    } else {
      setSelectGradeClassRoomForChampions(true);
    }
  }

  return (
    <>
      <SelectGradeClassRoomModal
        open={selectGradeClassRoomForStudents}
        returnSelectedGrade={setSelectedGrade}
        returnSelectedClassRoom={setSelectedClassRoom}
        close={() => setSelectGradeClassRoomForStudents(false)}
        action={"students"}
      />
      <SelectGradeClassRoomModal
        open={selectGradeClassRoomForChampions}
        returnSelectedGrade={setSelectedGrade}
        returnSelectedClassRoom={setSelectedClassRoom}
        close={() => setSelectGradeClassRoomForChampions(false)}
        action={"champions"}
      />
      <Layout>
        <h3 className="mt-6 mb-3 text-xl font-semibold text-gray-900">
          {t("Reports")}
        </h3>
        <div className="divide-y  divide-gray-200 overflow-hidden rounded-lg 200 bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 ">
          {actions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={classNames(
                actionIdx === 0
                  ? "ltr:rounded-tl-lg ltr:rounded-tr-lg rtl:rounded-tr-lg rtl:rounded-tl-lg"
                  : "",
                actionIdx === 1 ? "ltr:rounded-tr-lg rtl:rounded-tl-lg" : "",
                actionIdx === actions.length - 2
                  ? "ltr:rounded-bl-lg rtl:rounded-br-lg"
                  : "",
                actionIdx === actions.length - 1
                  ? "ltr:rounded-bl-lg ltr:rounded-br-lg rtl:rounded-br-lg rtl:rounded-bl-lg"
                  : "",
                "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    "inline-flex rounded-lg p-3 ring-4 ring-white"
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  {/* <a
                    href="{action.href}"
                    target="_blank"
                    rel="noreferrer"
                    className="focus:outline-none"
                  >
                    {/* Extend touch target to entire panel 
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </a> */}
                  <button onClick={() => showReport(action)}>
                    <span className="absolute inset-0" aria-hidden="true" />
                    {t(action.title)}
                  </button>
                </h3>
                {/* <p className="mt-2 text-sm text-gray-500">
                  Doloribus dolores nostrum quia qui natus officia quod et
                  dolorem. Sit repellendus qui ut at blanditiis et quo et
                  molestiae.
                </p> */}
              </div>
              <span
                className="pointer-events-none absolute ltr:right-6 rtl:left-6 top-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </Layout>
    </>
  );
}
