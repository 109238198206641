import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../slices/authSlice";
import reportServices from "../../../services/report.services";
import ReportLayout from "../../../components/root/ReportLayout";
import { useTranslation } from "react-i18next";
import NoData from "../../../components/common/NoData";
import LoadingData from "../../../components/common/LoadingData";
import FlatPickerDate from "../../../components/common/FlatePicker/PickerDate";
import FlatPickerMonth from "../../../components/common/FlatePicker/PickerMonth";
import FlatPickerRange from "../../../components/common/FlatePicker/PickerRange";

export default function PointsReport() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subRows, setSubRows] = useState([]);
  const [subIsLoading, setSubIsLoading] = useState(true);
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [teacher, setTeacher] = useState("");
  const [grades, setGrades] = useState(null);
  const [grade, setGrade] = useState("");
  const [classRoom, setClassRoom] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pages = [
    {
      name: t("Reports"),
      href: "/reports/index",
      current: false,
    },
    { name: t("PointsReport"), href: "#", current: true },
  ];

  useEffect(() => {
    getItems(1);
  }, [date, month, fromDate, toDate, teacher, grade]);

  useEffect(() => {
    getItemsDetails(1);
  }, [classRoom, teacher, date, month, fromDate, toDate]);

  useEffect(() => {
    getTeachers();
    getGrades();
  }, []);

  const getItems = async (page) => {
    setIsLoading(true);
    try {
      const response = await reportServices.getHomeworksAndPoints(
        page,
        limit,
        filter == "date" ? date : null,
        filter == "month" ? month : null,
        filter == "range" ? fromDate : null,
        filter == "range" ? toDate : null,
        teacher,
        grade
      );
      if (response.status === 200) {
        setRows(response.data?.report?.rows);
        setCount(response.data?.report?.count);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItems = async (limit, page) => {
    setIsLoading(true);
    try {
      const response = await reportServices.getHomeworksAndPoints(
        page,
        limit,
        date,
        month,
        fromDate,
        toDate,
        teacher,
        grade
      );
      if (response.status === 200) {
        setRows(response.data?.report?.rows);
        setLimit(limit);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getTeachers = () => {
    reportServices
      .getTeachers()
      .then((response) => {
        setTeachers(response.data?.report);
      })
      .catch((error) => {
        console.log("🚀 ~ getTeachers ~ error:", error);
      });
    //eslint-disable-next-line
  };

  const getGrades = () => {
    reportServices
      .getGradesWithClasses()
      .then((response) => {
        setGrades(response.data?.report);
      })
      .catch((error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
      });
  };

  const columns = [
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Grades")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">
          {row?.instituteGrade?.arabicName}
        </div>
      ),
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("ClassRoom")}
        </div>
      ),
      selector: (row) => (
        <button
          onClick={() => setClassRoom(row.id === classRoom ? null : row.id)} // Toggle
          className="text-primary underline"
        >
          {row?.arabicName}
        </button>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("totalHomeworks")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">{row?.totalHomeworks}</div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("totalClassPoints")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">{row?.totalClassPoints}</div>
      ),
      sortable: true,
    },
    teacher && {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("teacherGivenPoints")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">{row?.teacherGivenPoints}</div>
      ),
      sortable: true,
    },
    teacher && {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("teacherGivenHomeworks")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">{row?.teacherGivenHomeworks}</div>
      ),
      sortable: true,
    },
  ].filter(Boolean);

  const getItemsDetails = async (page) => {
    setSubIsLoading(true);
    try {
      const response = await reportServices.getClassroomHomeworks(
        page,
        limit,
        classRoom,
        teacher,
        filter == "date" ? date : null,
        filter == "month" ? month : null,
        filter == "range" ? fromDate : null,
        filter == "range" ? toDate : null
      );

      if (response?.status === 200) {
        setSubRows(response.data?.report?.rows);
        setCount(response.data?.report?.count);
        setSubIsLoading(false);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItemsDetails = async (limit, page) => {
    setSubIsLoading(true);
    try {
      const response = await reportServices.getClassroomHomeworks(
        page,
        limit,
        classRoom,
        teacher,
        filter == "date" ? date : null,
        filter == "month" ? month : null,
        filter == "range" ? fromDate : null,
        filter == "range" ? toDate : null
      );
      if (response.status === 200) {
        setSubRows(response.data?.report?.rows);
        setLimit(limit);
        setSubIsLoading(false);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout());
      }
    }
  };

  const subColumns = [
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Subject")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">{row.subject?.name}</div>
      ),
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Description")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">{row?.description}</div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Teacher")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">
          {row?.subject?.teacher?.user?.firstName}{" "}
          {row?.subject?.teacher?.user?.midName}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Status")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">{row?.status}</div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Views")}
        </div>
      ),
      selector: (row) => (
        <div className="hover:cursor-pointer">{row?.views}</div>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      <ReportLayout title={t("PointsReport")} pages={pages}>
        <div className="flex justify-end items-center gap-2 py-4">
          <select
            id="selectedFilter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="w-full sm:w-48 rounded-md border border-secondary bg-white py-2 px-4 font-medium text-left focus:border-none focus:outline-none focus:ring-2 focus:ring-secondary focus-visible:outline-none"
          >
            <option value="">{t("selectFilter")}</option>
            <option value="date">{t("date")}</option>
            <option value="month">{t("month")}</option>
            <option value="range">{t("range")}</option>
          </select>

          {filter === "date" && <FlatPickerDate setDate={setDate} />}

          {filter === "month" && <FlatPickerMonth setMonth={setMonth} />}

          {filter === "range" && (
            <FlatPickerRange setFromDate={setFromDate} setToDate={setToDate} />
          )}

          <select
            id="grade"
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
            className="w-full sm:w-48 rounded-md border border-secondary bg-white py-2 px-4 font-medium text-left focus:border-none focus:outline-none focus:ring-2 focus:ring-secondary focus-visible:outline-none"
          >
            <option value="">{t("filterByGrade")}</option>
            {grades?.map((grade, index) => (
              <option key={index} value={grade.id}>
                {grade.arabicName}
              </option>
            ))}
          </select>

          <select
            id="teacher"
            value={teacher}
            onChange={(e) => setTeacher(e.target.value)}
            className="w-full sm:w-48 rounded-md border border-secondary bg-white py-2 px-4 font-medium text-left focus:border-none focus:outline-none focus:ring-2 focus:ring-secondary focus-visible:outline-none"
          >
            <option value="">{t("filterByTeacher")}</option>
            {teachers?.map((teacher, index) => (
              <option key={index} value={teacher.id}>
                {teacher.user?.firstName} {teacher.user?.midName}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <DataTable
                  columns={columns}
                  data={rows}
                  progressPending={isLoading}
                  Header
                  pagination
                  paginationServer
                  highlightOnHover
                  paginationTotalRows={count}
                  onChangeRowsPerPage={getNextItems}
                  onChangePage={getItems}
                  noDataComponent={<NoData />}
                  progressComponent={<LoadingData />}
                />
              </div>
            </div>
          </div>
          {classRoom && (
            <div className="mt-6 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <DataTable
                    columns={subColumns}
                    data={subRows}
                    progressPending={subIsLoading}
                    Header
                    pagination
                    paginationServer
                    highlightOnHover
                    paginationTotalRows={count}
                    onChangeRowsPerPage={getNextItemsDetails}
                    onChangePage={getItemsDetails}
                    noDataComponent={<NoData />}
                    progressComponent={<LoadingData />}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </ReportLayout>
    </>
  );
}
