const arabic = {
  champion: "من البطل",
  Home: "الرئيسية",
  Name: "الأسم",
  Email: "البريد الإلكتروني",
  Password: "كلمة المرور",
  RememberMe: "تذكرني",
  ForgetPassword: "نسيت كلمة المرور ؟",
  Login: "تسجيل الدخول",
  Logout: "تسجيل الخروج",
  DontHaveAccountYet: "ليس لديك حساب ؟",
  CreateNewAccount: "إنشاء حساب جديد",
  IndexHeroHeader: "Lorem Ipsum",
  IndexHeroBody:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  FirstName: "ألإسم الاول",
  LastName: "ألإسم الأخير",
  ConfirmPassword: "تأكيد كلمة المرور",
  RegisterNewAccount: "تسجيل حساب جديد",
  AlreadyRegistered: "لدي حساب مسبقاً !",
  Dashboard: "لوحة التحكم",
  GradesAndClasses: "الصفوف و الفصول",
  Teachers: "الأساتذة",
  teachersList: "قائمة المعلمين",
  Students: "الطلاب",
  StudentName: "اسم الطالب",
  Parents: "أولياء الأمور",
  ParentsManagement: "إدارة اولياء الامور",
  PricesAndSubscriptions: "الاسعار و الاشتراكات",
  Reports: "التقارير",
  ContactUs: "تواصل معنا",
  ViewProfile: "الصفحة الشخصية",
  Statistic: "الإحصائيات",
  OTP: "رمز التحقق",
  ConfirmEmail: "تأكيد البريد الإلكتروني",
  DidntGetOTP: "لم تتحصل على رمز التحقق بعد ؟",
  ResendOTP: "إرسال رمز التحقق",
  ArabicName: "الإسم بالعربية",
  EnglishName: "الأسم بالانجليزية",
  PhoneNumber: "رقم الهاتف",
  Address: "العنوان",
  CompleteRegister: "إكمال التسجيل",
  SchoolInformation: "معلومات المؤسسة",
  ChangePassword: "تغير كلمة المرور",
  NewPassword: "كلمة المرور الجديدة",
  ResetPassword: "إعادة تعيين كلمة المرور",
  Continue: "الإستمرار",
  Grades: "الصفوف الدراسية",
  AddNewGrade: "إضافة صف جديد ",
  CreatedAt: "تاريخ الإنشاء",
  UpdatedAt: "اخر تحديث",
  Cancel: "إلغاء",
  Save: "حفظ",
  Remove: "حذف",
  Classes: "الفصول",
  AddClass: "إضافة فصل",
  AddNewClasses: "إضافة فصول جديدة",
  AddGrade: "إضافة صف",
  EditGrade: "تعديل بيانات الصف",
  Delete: "حذف",
  DeleteThisItem: "حذف هذا العنصر",
  GradeDetails: "تفاصيل الصف",
  ClassRooms: "الفصول الدراسية",
  EditClassRoom: "تعديل بيانات الفصل",
  ClassRoomDetails: "تفاصيل الفصل",
  UploadExcel: "تحميل ملف Excel",
  AddNewStudents: " إضافة طلاب جدد",
  UserName: "إسم المستخدم",
  Parent: "ولي الأمر",
  NotAssignedyet: "غير محدد بعد",
  ParentsContacts: "معلومات اتصال ولي الأمر",
  BirthDate: "تاريخ الميلاد",
  Gender: "الجنس",
  Female: "أنثى",
  Male: "ذكر",
  SelectedParent: "ولي الأمر",
  AddNewStudent: "إضافة طالب",
  CLassRooms: "الفصول",
  SelectedClassRoom: "إختر الفصول",
  StudentDetails: "تفاصيل الطالب",
  StudentInformation: "بيانات الطالب",
  PersonalDetails: "المعلومات الشخصية",
  FullName: "الأسم بالكامل",
  Code: "الرمز",
  Confirmed: "مؤكد",
  NotConfirmedYet: "لم يتم التحقق بعد",
  RegistrationDate: "تاريخ التسجيل",
  Attachments: "الملحقات",
  Download: "تحميل",
  export: "تصدير",
  exportFormat: "تنسيق التصدير",
  exportAsExcel: "تصدير بصيغة إكسل",
  exportAsPdf: "تصدير بصيغة PDF",
  Details: "التفاصيل",
  ClassName: "إسم الفصل",
  Grade: "السنة الدراسية",
  CreattionDate: "تاريخ الإنشاء",
  ParentsManagements: "إدارة اولياء الامر",
  SendEmail: "إرسال بريد إلكتروني",
  Send: "إرسال",
  Description: "التفاصيل",
  Notes: "الملاحظات",
  AddParent: "إضافة ولي أمر",
  UploadAndAddNewStudents: "إضافة طلاب جدد",
  FirstDownloadSheetTemplate:
    "لرفع بيانات الطلاب بإستخدام ملف الإكسل يرجاء اتباع الخطوات التاليه:",
  FirstStep: "الخطوة الاولى:",
  SecondStep: "الخطوة الثانية:",
  GetStartedByDownloadingaTemplate:
    "قم بتحميل ملف إضافة الطلاب و أختر الصف و الفصل لكل طالب",
  Downlad: "تحميل",
  NewStudentsSheet: "اضغط هنا لتحميل نموزج الإكسل الخاص ببيانات الطالب",
  SaveUploadedFile: "اضف بيانات الطالب في نموذج الإكسل الذي قمت بتحميلة،",
  SaveUploadedTeacherFile:
    "اضف بيانات الاساتذة في نموذج الإكسل الذي قمت بتحميلة،",
  SaveFile: "احفظ الملف",
  ReUpload: "وقم بإعادة رفع الملف من هنا",
  ChooseFile: "إختر الملف",
  EditStudent: "تعديل بيانات الطالب",
  UpdateStudentDetails: "تعديل بيانات الطالب",
  ChangeCLassRoom: "تعديل الفصل",
  SearchByEmailPhoneName: "إبحث عن طريق الاسم, البريد الإلكتروني أو رقم الهاتف",
  AssignedCLassRooms: "الفصول المسندة إليه",
  AddNewTeachers: "إضافة أساتذة جدد",
  AddNewTeacher: "إضافة استاذ جديد",
  EditTeacherDetails: "تعديل بيانات الاستاذ",
  TeacherDetails: "تفاصيل الأستاذ",
  UploadAndAddNewTeachers: "إضافة أساتذة جدد",
  FirstDownloadTeachersSheetTemplate:
    "لرفع بيانات الاساتذة بإستخدام ملف الإكسل يرجاء اتباع الخطوات التاليه:",
  GetStartedByDownloadingaTeachersTemplate:
    "قم بتحميل ملف إضافة الأساتذة و أختر الفصل لكل استاذ",
  // NewTeachersSheet: "ملف أساتذة جدد",
  NewTeachersSheet: "اضغط هنا لتحميل نموزج الإكسل الخاص ببيانات الاستاذ",
  SaveUploadedTeachersFile:
    "ثم قم بحفظ التغييرات بعد إضافة الأساتذة الجدد الجدد و ارف الملف هنا",
  SendNotification: "إرسال إشعار",
  SelectParent: "إختر ولي الأمر",
  SearchParents: "بحث بالأسم ,  البريد الإلكتروني أو رقم الهاتف",
  SendEmailTo: "إرسال بريد إلكتروني الى",
  WriteMessage: "تفاصيل الرسالة ...",
  Subject: "الموضوع",
  SendNotificationTo: "إرسال إشعار إالى",
  CreateParent: "إضافة ولي أمر",
  NewParent: "ولي أمر جديد",
  GradesClassrooms: "الصفوف و الفصول",
  ClassRoom: "الفصل",
  ClassRoomsOf: "فصول ",
  Champions: "الأبطال الأكثر حصولاً على النقاط",
  Print: "طباعة",
  EditClassRooms: "تعديل الفصول",
  Create: "إضافة ",
  Select: "إختر من القائمة ..",
  SelectOrCreate: "إختر من القائمة أو قم بإضافة مدخل جديد",
  SelectGradeFirst: "إختر الفصل الدراسي",
  ShowStudents: "عرض الطلاب",
  Search: "   بحث ..",
  ParentPhoneNumber: "رقم هاتف ولي الأمر",
  IdentityNumber: "رقم الهوية",
  PrintStudents: "طباعة الطلاب",
  View: "عرض",
  Status: "الحالة",
  Views: "المشاهدات",
  instituteGrade: "مرحلة دراسية",
  instituteClassRoom: "فصل دراسي",
  Teacher: "آستاذ",
  Student: "طالب",
  UpdatePassword: "تغير كلمة المرور",
  StudentsOf: "طلاب",
  selectClassRoom: "إختر الفصل",
  SaveChanges: "حفظ التغيرات ",
  //Messages
  Required: "مطلوب",
  PasswordMin3: "يجب أن تتكون كلمة المرور من 3 أحرف كحد أدنى",
  PasswordMustMatch: "يجب أن تتطابق كلمات المرور",
  InvalidEmail: "البريد الإلكتروني غير صحيح",
  OneRecordMin: "مطلوب سجل واحد كحد أدنى",
  SureWantToDeleteThisItem: "هل أنت متأكد من حذف هذا العنصر؟",
  ParentInformation: "معلومات ولي الأمر",
  NotifyParents: "إخطار ولي الامر",
  EmailPasswordIncorrect: "البريد الإلكتروني او كلمة المرور غير صحيحتين",
  UploadTeachersCompleted: "تم إضافة الاساتذة الجدد بنجاح !",
  UploadTeachersFailed:
    "تعذر إضافة الاساتذة الجدد, رقم الهاتف يجب أن لا يتكرر الرجاء المحاولة مرة أخرى",
  UploadStudentsCompleted: "تم إضافة الطلاب الجدد بنجاح !",
  UploadStudentsFailed:
    "تعذر إضافة طلاب الجدد رقم الهاتف و رقم الهوية يجب أن لا يتكررا, الرجاء المحاولة مرة أخرى",
  NoData: "لا توجد بيانات",
  NoRecords: "لا توجد سجلات حالياً ،، قم بٌضافة عناصر جديدة",
  LoadingData: "الرجاء الإنتظار",
  EmailAlreadyTakenOrSomethingWentWroing:
    "البريد الإلكتروني موجود مسبقاً .. الرجاء المحاولة مرة آخرى",
  SomethingWentWroing: "حدث خطأ ما ،، الرجاء المحاولة في مرة آخرى",
  NewParentsAddedAearchAgain: "تم إضافة ولي امر جديد .. إختر من القائمة",
  schoolEmailIsAlreadyTaken: "البريد الإلكتروني للمؤسسة موجود مسبقاً",
  NoParentsInfo: "لم يتم إضافة ولي أمر بعد",
  EmailSent: "تم إرسال البريد الإلكتروني بنجاح.",
  NotificationSent: "تم إرسال الإشعار بنجاح.",
  changesSaved: "تم حفظ التغيرات بنجاح!",
  changesNotSaved: "حدث خطأ ما .. تعذر حفظ التغيرات",
  DataUpdated: "تم تعديل البيانات بنجاح.",
  ParentsContactsDesc:
    "اسم المستخدم و الرقم السري لولي الامر هو رقم الجوال المدخل.",
  PasswordDesc:
    "كلمة المرور الخاصة بالطالب يمكن اضافة نفس رقم الهوية كرقم سري.",
  Rounds: "الجولات",
  RoundTime: "زمن الجولة",
  Periodic: "دوري",
  Specific: "تاريخ محدد",
  Period: "الفترة",
  Weekly: "اسبوعي",
  Monthly: "شهري",
  FromDate: "من تاريخ",
  ToDate: "الى تاريخ",
  EditRound: "تعديل بيانات الجولة",
  UpdateRound: "تم تعديل الجولة بنجاح",
  enjoy_free: "إستمتع بتجربتك المجانية",
  enjoy_free_des: "  المهم شاركنا رايك لتحقيق الافضل",
  identityDesc: "اسم المستخدم للطالب في تطبيق من البطل سيكون الرقم المدخل هنا ",
  EmailOrPhoneAlreadyTakenOrSomethingWentWroing:
    "البريد الإلكتروني أو رقم الهاتف موجود مسبقاً .. الرجاء المحاولة مرة أخرى",
  ThereIsEmailOrPhoneAlreadyTakenOrSomethingWentWroing:
    "هنالك بريد إلكتروني أو رقم هاتف موجود مسبقاً .. الرجاء المحاولة مرة أخرى",
  HowToUse: "شرح طريقة الإستخدام",
  Attendance: "الحضور",
  absent: "غائب",
  attend: "حاضر",
  permitted: "مستأذن",
  remote: "عن بعد",
  pickDate: "إختر التاريخ",
  Subjects: "المواد",
  AddSubjects: "إضافة مواد",
  SubjectName: "إسم المادة",
  EditSubject: "تعديل المواد",
  AttendancePoints: "نقاط الحضور",
  save: "حفظ",
  arabicDescription: "الوصف بالعربي",
  englishDescription: "الوصف بالانجليزي",
  SavedSuccessfully: "تم الحفظ  بنجاح",
  cLassRooms: "الفصول",
  superviser: "مشرف",
  classRoomName: "اسم الفصل",
  true: "نعم",
  false: "لا",
  subjects: "المواد",
  subjectGroups: "مجموعات المادة",
  groupName: "اسم المجموعة",
  studentStatistics: "إحصائيات الطالب",
  budget: "الميزانية",
  budgetDays: "عدد ايام الميزانية",
  totalKids: "عدد الاطفال",
  amountsPerPoints: "المبلغ مقابل النقاط",
  pointsPerAmounts: "النقاط مقابل المبلغ",
  AttendanceReport: "تقرير الحضور",
  totalStudents: "عدد الطلاب",
  totalAttendance: "اجمالي الحضور",
  totalAbsence: "اجمالي الغائبين",
  totalRemote: "إجمالي عن بعد",
  totalPermitted: "إجمالي المسموح لهم",
  pointsExpireAfter: "إنتهاء صلاحية النقاط المكتسبة بعد - بالأيام ",
  configs: "الإعدادات",
  PointsConfigs: "النقاط",
  attendanceConfgis: "الحضور",
  CreateFailed: "حدث خطأ ما أثناء إضافة سجل جديد",
  UpdateFailed: "حدث خطأ ما أثناء تحديث بيانات السجل",
  DeleteFailed: "حدث خطأ ما أثناء حذف السجل",
  Error: "حدث خطأ ما . الرجاء المحاولة مرة أخرى",
  AddNewGiftCard: "إضافة كرت هدية جديد",
  title: "الاسم",
  quantity: "الكمية",
  points: "النقاط",
  totalPoints: "مجموع النقاط",
  homeworks: "الواجبات المنزلية",
  totalHomeworks: "مجموع الواجبات المنزلية",
  PointsReport: "تقرير النقاط والواجبات",
  totalClassPoints: "مجموع نقاط الفصل",
  teacherGivenHomeworks: "الواجبات التي أعطاها المعلم",
  teacherGivenPoints: "النقاط التي أعطاها المعلم",
  selectFilter: "حدد عامل التصفية",
  filterByTeacher: "تصفية حسب المعلم",
  filterByGrade: "تصفية حسب الصف",
  date: "حسب التاريخ",
  month: "حسب الشهر",
  range: "حسب المدى",
  exchangeInstruction: "تعليمات الإستبدال",
  GiftCards: "كروت الهدايا",
  AddGiftCards: "إضافة كرت جديد",
  expireAt: "صالحة حتى",
  type: "النوع",
  RedeemCard: "هدية رقمية",
  ExhcangeCard: "هدية عينية",
  ExchangePoints: "إستبدال النقاط",
  GiftDetails: "تفاصيل الهدية",
  GiftsSupervisor: "مشرف الهدايا",
  EditAd: "تعديل الإعلان",
  url: "الرابط",
  PublishAd: "نشر إعلان",
  ads: "الإعلانات",
  sar: "ريال",
  point: "نقطة",
  NoCodes: "لا توجد كروت هدايا بعد",
  Codes: "كروت الهدايا",
  AddCode: "إضافة كرت",
  ExchangeCard: "هدية عينية",
  adTitle: "العنوان",
  adDescription: "وصف الإعلان",
  optionalField: "حقل إختياري",
  SupervisedCLassRooms: "فصول الإشراف (تدوين الحضور)",
  roundMode: "نظام الجولات",
  pointsMode: "نظام النقاط",
  appMode: "تحديد طريقة عمل التطبيق",
  roundsModeDescription:
    "هنا يمكن لكل طالب جمع النقاط للتنافس على المركز الأول (الجوائز تحدد لمراكز معينة فقط )",
  pointsModeDescription:
    "تجميع نقا قابلة للإستبدال و هنا يمكن لكل طالب جمع النقاط و شراء الجائزة بالنقاط المجمعة",
};

export default arabic;
