import { useRef } from "react";
import Body from "./Body";
import ReportHeader from "./ReportHeader";
import { useReactToPrint } from "react-to-print";
import Layout from "./Layout";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
// import Container from "./Container";
// import Breadcrumb from "../Breadcrumb";

const ReportLayout = ({ title, pages, children }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Layout>
      <div className="min-h-screen bg-slate-50">
        <div className="flex justify-between items-center gap-2 py-4">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center gap-4">
              <li>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    {window.localStorage.getItem("i18nextLng") === "ar" ? (
                      <ChevronLeftIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronRightIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                    <a
                      href={page.href}
                      className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={page.current ? "page" : undefined}
                    >
                      {page.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>

        <ReportHeader reportTitle={title} doPrint={handlePrint} />
        <div ref={componentRef} id="report" className="flex flex-1 flex-col">
          <Body>{children}</Body>
        </div>
      </div>
    </Layout>
  );
};

export default ReportLayout;
